<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :plan-options="planOptions"
      :assignee-options="assigneeOptions"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <div no-body class="mb-0">
      <div class="mb-1 mt-1">
      
        <b-row>
        


          <!-- Search -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-end justify-content-end">
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              /> -->
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Add Positions</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

 

        <b-row>
      <b-col md="3" v-for="(listItem, index) in fetchData" :key="index">
        <b-card class="card-app-design">
        <b-row>
        <b-col class="d-flex align-items-start justify-content-start">
          <b-card-title class="mt-1 mb-75">
           <h5> <b-link  :href="`/apps/job/edit/${listItem.id}`"  >{{ listItem.title }}</b-link></h5> 
          </b-card-title>
         
        </b-col>

         <b-col md="1" class="d-flex align-items-end justify-content-end">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-primary"
              v-b-modal="modalId(listItem.id)"
              class="btn-icon ml-0"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle"></span>
            </b-button>
            <b-modal :id="'modal' + listItem.id" title="Confirm Delete?">
              <div class="alert">
                Are you sure to remove
               <b> {{listItem.title}}</b>
                ?
              </div>
              <div slot="modal-footer">
                <p>
                  <b-button
                    size="md"
                    class="float-left mr-1"
                    variant="secondary"
                    @click="$bvModal.hide('modal' + listItem.id)"
                  >
                    <i class="fas fa-times"></i> No
                  </b-button>
                  <b-button
                    size="md"
                    class="float-right"
                    variant="danger"
                    @click="deleteData(listItem.id)"
                  >
                    <i class="fas fa-check"></i> Yes
                  </b-button>
                </p>
              </div>
            </b-modal>
         </b-col>
         </b-row>
        <b-card-text class="font-small-2 mb-1">
            {{ listItem.companyName }}
          </b-card-text>

          <div class="mb-1">
            <h6 class="section-label mt-1">Recruiters</h6>
            <b-avatar-group  class="mt-0 pt-50 min-h-35" size="33">
              <b-avatar
              v-for="avatar in listItem.users"
              :key="avatar.avatarFileUrl"
              size="30"
               v-b-tooltip.hover.top="avatar.userFullName"
              :src="avatar.avatarFileUrl ? (avatar.avatarFileUrl.startsWith('data:image') ? avatar.avatarFileUrl : `data:image/jpeg;base64,${avatar.avatarFileUrl}`) : ''"
              class="pull-up"
            />
            </b-avatar-group>
          </div>
          <!--/ design group -->

          <b-row class="design-planning-wrapper d-flex">
             <div class="m-0 d-flex">
            
            <b-button
              variant="text"
              class="btn-icon ml-0"
              :to="{
                name: 'apps-findcondidate',
                params: { id: listItem.id },
              }"
            >
              <b-badge variant="light-warning" class="mr-1">
                Add Candidate
              </b-badge></b-button
            >
          </div>
             <div class="m-0">
            
            <b-button
              variant="text"
              class="btn-icon ml-0"
              :to="{
                name: 'companies-edit',
                params: { id: listItem.companyId },
              }"
            >
              <b-badge variant="light-primary" class="mr-1">
                Other Positions
              </b-badge></b-button
            >
          </div>
          </b-row>
          <span class="font-weight-bold text-body-heading mr-1"
                >{{ dateFormatDay(listItem.endDateUtc) }} Days to Close</span
              >
         <b-progress
         class="mt-1 mb-2"
          :value="listItem.percent"
          max="100"
          height="7px"
          :variant="listItem.percent>70?'danger':(listItem.percent>50?'warning':'success')"
          striped
        />
          <b-button
            class="m-0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant=""
            block
            :to="{ name: 'companies-candidate', params: { id: listItem.id } }"
          >
            Show Status
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBModal,
    BAvatarGroup,
  VBTooltip,
  BProgress,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import UsersListFilters from "./JobListFilters.vue";
import useUsersList from "./useJobList";
import userStoreModule from "../jobStoreModule";
import UserListAddNew from "./JobListAddNew.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from "@/router";
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import moment from "moment";

export default {
  components: {
    flatPickr,
    UsersListFilters,
    UserListAddNew,
    ToastificationContent,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    VBModal,
    vSelect,
    BAvatarGroup,
    BProgress,
  
  },
directives: {
    Ripple,
     'b-tooltip': VBTooltip,
  },
  methods: {
     modalId(id){
    return 'modal' + id
    },
     dateFormatDay(nextDay) {
           let day = 0;
      if(nextDay && nextDay!= null)
      {
        day = moment(nextDay).diff(moment(new Date()), "days");
      }
       
      return day>0 ?day : 0;
    },
    deleteData(id) {
      this.$bvModal.hide("modal" + id);
      store
        .dispatch("job/delete", {
          id: id,
        })
        .then((response) => {
          this.refetchData();
         
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(
            error.response.data.errors
          )) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: value,
              },
            });
          }
        });
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "job";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

   

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      fetchData,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      assigneeOptions,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useUsersList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      assigneeOptions,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      fetchData,
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    };
  },
};
</script>



<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.min-h-35{
min-height: 35px;
}
.per-page-selector {
  width: 90px;
}
</style>
